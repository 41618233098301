import { Button, Loading } from "@nextui-org/react";
import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";

const DeleteButton = ({ id, product, setOpen }) => {
  const [load, setLoad] = useState(false);

  //Download Product PDF
  const downloadPDf = async () => {
    try {
      setLoad(true);
      const res = await fetch(`https://sca-token-api.vercel.app/delete/get-otp/${id}`);
      if (!res.ok) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
      }

      const blobz = await res.json();
      if (!blobz.success) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
      }

      setLoad(false);
      setOpen(id);
    } catch (err) {
      setLoad(false);
      window.alert(err?.message);
    }
  };

  return (
    <>
      <Button
        onClick={() => downloadPDf()}
        size="xs"
        rounded
        color="error"
        auto
        flat
        disabled={load}
      >
        {load ? <Loading size="xs" /> : <AiFillDelete />}
      </Button>
    </>
  );
};

export default DeleteButton;
