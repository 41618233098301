import { Button, Input, Loading, Modal, Text } from "@nextui-org/react";
import { useEffect, useState } from "react";

const DeleteModal = ({ visible, closeHandler, refetch }) => {
  const [value, setValue] = useState("");
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setValue("");
  }, []);

  const downloadPDf = async () => {
    try {
      setLoad(true);
      const res = await fetch(
        `https://sca-token-api.vercel.app/delete/validate-otp/${value}?id=${visible}`
      );
      if (!res.ok) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
      }

      const blobz = await res.json();
      if (!blobz.success) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
      }

      refetch();
      setLoad(false);
      closeHandler();
      window.alert("Entry Deleted");
    } catch (err) {
      setLoad(false);
      window.alert(err?.message);
    }
  };

  return (
    <>
      <Modal
        closeButton={false}
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
        blur
        css={{ p: 12 }}
      >
        <Modal.Header>
          <Text id="modal-title" b size={18}>
            Delete Entry
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Input
            placeholder="Enter OTP"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              gap: "12px",
              flexWrap: "nowrap",
              justifyContent: "right",
            }}
          >
            <Button onClick={closeHandler} auto light>
              Close
            </Button>
            <Button auto color="error" onClick={downloadPDf}>
              {load ? <Loading size="xs" /> : "Delete"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
